<template>
  <div>
    <el-tabs v-model="permissionSetting" class="el-tabs">
      <!-- 角色 -->
      <el-tab-pane :label="this.$t('account.role')" name="role">
        <div class="topButton">
          <el-button class="added" @click="roleAdded"
            ><img src="@/assets/Frame_9.png" />{{
              $t("offer.newlyAdded")
            }}</el-button
          >
          <div>
            <!-- <el-button class="delete" @click="frozen(jurisdictionData)">{{
              $t("account.frozen")
            }}</el-button> -->
            <el-button class="delete" @click="roleDelete(jurisdictionData)">{{
              $t("offer.delete")
            }}</el-button>
          </div>
        </div>

        <el-table
          ref="multipleTable"
          :data="roleTableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          class="mt title"
        >
          <el-table-column
            type="selection"
            align="center"
            width="55"
          ></el-table-column>

          <el-table-column
            prop="authorityName"
            align="center"
            :label="this.$t('account.roleName')"
          >
          </el-table-column>

          <!-- <el-table-column
            prop="involvedPlate"
            align="center"
            :label="this.$t('account.involvedPlate')"
          >
          </el-table-column>

          <el-table-column
            prop="record"
            align="center"
            :label="this.$t('account.record')"
          >
          </el-table-column> -->

          <el-table-column
            align="center"
            :label="this.$t('account.operation')"
            width="130"
          >
            <template v-slot="scope">
              <!-- :disabled="disable" -->
              <el-button @click="roleJurisdiction(scope.row)" class="modify">{{
                $t("account.jurisdiction")
              }}</el-button>
              <el-button
                @click="roleModify(scope.row)"
                class="modify"
                style="margin-left: 0px; color: gray; margin-top: -7px"
                >{{ $t("offer.modify") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <!-- 人员 -->
      <el-tab-pane :label="this.$t('account.personnelList')" name="personnel">
        <div class="topButton">
          <el-button class="added" @click="personnelAdded"
            ><img src="@/assets/Frame_9.png" />{{
              $t("offer.newlyAdded")
            }}</el-button
          >
          <el-button
            class="delete"
            @click="personnelDelete(jurisdictionData)"
            >{{ $t("offer.delete") }}</el-button
          >
        </div>

        <el-table
          ref="multipleTable"
          :data="personnelTableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          class="mt title"
        >
          <el-table-column
            type="selection"
            align="center"
            width="55"
          ></el-table-column>

          <el-table-column
            prop="userName"
            align="center"
            :label="this.$t('account.userName')"
            width="300"
          >
          </el-table-column>

          <el-table-column
            prop="mobile"
            align="center"
            :label="this.$t('account.phoneNumber')"
          >
          </el-table-column>

          <el-table-column
            prop="email"
            align="center"
            :label="this.$t('offer.mailbox')"
          >
          </el-table-column>
          <el-table-column
            prop="authority.authorityName"
            align="center"
            :label="this.$t('account.role')"
          >
          </el-table-column>

          <el-table-column
            align="center"
            :label="this.$t('account.operation')"
            width="130"
          >
            <template v-slot="scope">
              <!-- 详情 -->
              <!-- <el-button class="modify" @click="details(scope.row)">{{
                $t("account.details")
              }}</el-button> -->
              <el-button @click="personnelModify(scope.row)" class="modify">
                <!-- style="margin-left: 0px; color: gray" -->
                {{ $t("offer.modify") }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <!-- 角色名称弹框 -->
    <el-dialog
      :title="this.$t('account.roleManagement')"
      :visible="roleDialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <el-form
        :model="roleRuleForm"
        :rules="roleRules"
        label-width="20%"
        ref="roleRuleForm"
        class="login"
      >
        <el-form-item prop="userName" :label="this.$t('account.rolename')">
          <el-input v-model="roleRuleForm.userName" placeholder=""> </el-input>
        </el-form-item>
        <!-- <el-form-item
          prop="phoneNumber"
          :label="this.$t('account.phoneNumber')"
        >
          <el-input v-model="ruleForm.phoneNumber" placeholder=""> </el-input>
        </el-form-item>
        <el-form-item prop="mailbox" :label="this.$t('offer.mailbox')">
          <el-input v-model="ruleForm.mailbox" placeholder=""> </el-input>
        </el-form-item> -->
      </el-form>
      <template v-slot:footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false" class="preview">{{
            $t("common.cancel")
          }}</el-button>
          <el-button type="primary" class="preservation" @click="rolePrimary">{{
            $t("offer.preservation")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 人员弹框 -->
    <el-dialog
      :title="this.$t('account.personnel')"
      :visible="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        label-width="20%"
        ref="ruleForm"
      >
        <!-- 用户名 -->
        <el-form-item prop="userName" :label="this.$t('account.userName')">
          <el-input v-model="ruleForm.userName" placeholder=""> </el-input>
        </el-form-item>
        <!-- 电话号码 -->
        <el-form-item
          prop="phoneNumber"
          :label="this.$t('account.phoneNumber')"
        >
          <el-input v-model="ruleForm.phoneNumber" placeholder=""> </el-input>
        </el-form-item>
        <!-- 邮箱 -->
        <el-form-item
          prop="mailbox"
          :label="this.$t('offer.mailbox')"
          class="mailbox"
        >
          <el-row>
            <el-col :span="16">
              <el-input
                class="mailboxInput"
                v-model="ruleForm.mailbox"
                placeholder=""
                :disabled="disabled"
                autocomplete="new-password"
              >
              </el-input>
            </el-col>
            <el-col :span="8" v-if="passwordType == 1">
              <span>@e.sokogate.com</span>
            </el-col>
          </el-row>
        </el-form-item>
        <!-- password 密码 -->
        <el-form-item
          prop="password"
          :label="this.$t('account.password')"
          v-if="passwordType == 1"
        >
          <el-input
            v-model="ruleForm.password"
            placeholder=""
            show-password
            autocomplete="new-password"
          >
          </el-input>
        </el-form-item>
        <!-- Confirm password 确认密码 -->
        <el-form-item
          prop="confirmPassword"
          :label="this.$t('account.confirmPassword')"
          v-if="passwordType === 1"
        >
          <el-input
            v-model="ruleForm.confirmPassword"
            placeholder=""
            show-password
          >
          </el-input>
        </el-form-item>
        <!-- 角色 -->
        <el-form-item prop="role" :label="this.$t('account.role')">
          <el-select v-model="ruleForm.role" placeholder="">
            <el-option
              v-for="item in role"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- <el-input v-model="ruleForm.role" placeholder=""> </el-input> -->
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false" class="preview">{{
            $t("common.cancel")
          }}</el-button>
          <el-button
            type="primary"
            class="preservation"
            @click="preservation(ruleForm.role)"
            >{{ $t("offer.preservation") }}</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { postData } from "@//helper/api";
export default {
  data() {
    // 邮箱
    var mailbox = (rule, value, callback) => {
      const reg = /^[A-Za-z0-9]*$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t("account.only")));
      }
    };
    // 验证再次输入密码是否一致
    var confirmPassword = (rule, value, callback) => {
      if (value === this.ruleForm.password) {
        callback();
      } else {
        callback(new Error(this.$t("register.confirmPasswordreg")));
      }
    };
    return {
      permissionSetting: "role", // 默认选择
      dialogVisible: false, // 人员弹框
      jurisdictionData: [], // 勾选的数据
      disable: true, // 是否禁用
      id: "", // 角色ID
      type: 0, // 判断新建和编辑的值
      roleDialogVisible: false, // 角色弹框
      roleTableData: [], //角色列表
      storeId: "", // 店铺ID
      userId: "", // 用户ID
      disabled: false, // 判断用户邮箱是否可输入
      passwordType: 1, // 判断输入密码框是否显示
      // 角色form
      roleRuleForm: {
        userName: "",
        phoneNumber: "",
        mailbox: "",
      },
      roleRules: {
        userName: [
          {
            required: true,
            message: this.$t("account.userNames"),
            trigger: "blur",
          },
        ],
      },
      // 人员form
      ruleForm: {
        userName: "",
        phoneNumber: "",
        mailbox: "",
        role: "",
        password: "",
        confirmPassword: "",
      },
      rules: {
        userName: [
          {
            required: true,
            message: this.$t("account.userNames"),
            trigger: "blur",
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: this.$t("account.phoneNumbers"),
            trigger: "blur",
          },
        ],
        mailbox: [
          {
            required: true,
            message: this.$t("account.mailboxs"),
            trigger: "blur",
          },
          { validator: mailbox, trigger: "blur" },
        ],
        role: [
          {
            required: true,
            message: this.$t("account.roles"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("login.passwords"),
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: this.$t("login.passwordregss"),
            trigger: "blur",
          },
          { validator: confirmPassword, trigger: "blur" },
        ],
      },
      // 人员列表
      personnelTableData: [],
      role: [], // 角色名称数据
    };
  },
  // computed: {
  //   // 删除选择的数据
  //   roles: function () {
  //     return this.roleTableData.map((v) => {
  //       return {
  //         ...v.authorityName,
  //       };
  //     });
  //   },
  // },
  created() {
    this.storeId = localStorage.getItem("storeId");
    this.roleList();
    this.personnelList();
  },
  methods: {
    // 角色列表
    roleList() {
      postData("POST", "authority/getListByStoreId", {
        id: this.storeId,
      }).then((res) => {
        // console.log("roleList:", res);
        this.roleTableData = res.data;
        this.role = res.data.map((v) => {
          return {
            value: v.authorityId,
            label: v.authorityName,
          };
        });
        // console.log("this.role:", this.role);
      });
    },
    // 角色选择的数据
    handleSelectionChange(val) {
      //   this.logisticsData = val;
      this.jurisdictionData = val;
      // console.log("this.jurisdictionData:", this.jurisdictionData);
    },
    // 角色新建
    roleAdded() {
      this.roleRuleForm.userName = "";
      this.type = 1;
      this.roleDialogVisible = true;
    },
    // 冻结
    // frozen(jurisdictionData) {
    //   console.log("jurisdictionData:", jurisdictionData);
    // },
    // 角色删除
    roleDelete(jurisdictionData) {
      // console.log("jurisdictionData:", jurisdictionData);
      let authorityId = [];
      jurisdictionData.map((item) => {
        authorityId.push(item.authorityId);
      });
      // console.log("authorityId:", authorityId);
      postData("POST", "authority/delList", {
        ids: authorityId,
        storeId: this.storeId,
      }).then(() => {
        this.roleList();
      });
    },
    // 角色权限
    roleJurisdiction(row) {
      // console.log("scope.row:", row);
      this.$router.push({
        path: "/account/roleDatail",
        query: {
          row: encodeURIComponent(JSON.stringify(row)),
        },
      });
    },
    // 角色编辑
    roleModify(row) {
      this.roleDialogVisible = true;
      this.type = 2;
      this.id = row.authorityId;
      this.roleRuleForm.userName = row.authorityName;
    },

    // 角色弹框保存
    rolePrimary() {
      // 新增
      if (this.type === 1) {
        this.$refs["roleRuleForm"].validate((valid) => {
          if (valid) {
            postData("POST", "authority/add", {
              authorityId: "",
              authorityName: this.roleRuleForm.userName,
              parentId: "",
              storeId: this.storeId,
            }).then((res) => {
              // console.log("新增:", res);
              if (res.errcode === 0) {
                this.roleDialogVisible = false;
                this.roleList();
              } else {
                this.$message({
                  message: this.$t("account.userNames"),
                  type: "error",
                });
              }
            });
          } else {
            this.$message({
              message: this.$t("account.userNames"),
              type: "error",
            });
          }
        });
      }
      // 编辑
      else {
        this.$refs["roleRuleForm"].validate((valid) => {
          if (valid) {
            postData("POST", "authority/edit", {
              authorityName: this.roleRuleForm.userName,
              authorityId: this.id,
              storeId: this.storeId,
            }).then(() => {
              // console.log("编辑:", res);
              this.roleDialogVisible = false;
              this.roleList();
            });
          } else {
            this.$message({
              message: this.$t("resident.Please"),
              type: "error",
            });
          }
        });
      }
    },
    // 人员列表
    personnelList() {
      // console.log(this.storeId);
      postData("POST", "user/getListByStorId", {
        id: this.storeId,
      }).then((res) => {
        // console.log("personnelList:", res);
        this.personnelTableData = res.data;
        // console.log("this.personnelTableData:", this.personnelTableData);
      });
    },
    // 人员新建
    personnelAdded() {
      this.type = 1;
      this.disabled = false;
      this.passwordType = 1;
      this.userId = "";
      this.ruleForm.userName = "";
      this.ruleForm.phoneNumber = "";
      this.ruleForm.mailbox = "";
      this.ruleForm.role = "";
      this.dialogVisible = true;
    },
    // 人员编辑
    personnelModify(row) {
      // console.log("row", row);
      this.type = 2;
      this.passwordType = 2;
      (this.userId = row.userId),
        (this.ruleForm.userName = row.userName),
        (this.ruleForm.phoneNumber = row.mobile),
        (this.ruleForm.mailbox = row.email);
      this.ruleForm.role = row.authority.authorityName;
      // this.ruleForm.password=row.
      this.disabled = true;
      this.dialogVisible = true;
    },
    // 人员删除
    personnelDelete(jurisdictionData) {
      // console.log("jurisdictionData:", jurisdictionData);
      let userId = [];
      jurisdictionData.map((item) => {
        userId.push(item.userId);
      });
      // console.log("userId:", userId);
      postData("POST", "user/delList", {
        ids: userId,
      }).then(() => {
        // console.log("删除:", res);
        this.personnelList();
      });
    },
    // 人员保存
    preservation(role) {
      // 新增
      if (this.type === 1) {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            postData("POST", "user/addChildren", {
              storeId: this.storeId,
              authorityId: role,
              userName: this.ruleForm.userName,
              mobile: this.ruleForm.phoneNumber,
              email: this.ruleForm.mailbox,
              password: this.ruleForm.password,
            }).then((res) => {
              // console.log("新增：", res);
              if (res.errcode == 0) {
                this.dialogVisible = false;
                this.personnelList();
              } else {
                this.$message({
                  message: this.$t("login.emailIsUsed"),
                  type: "error",
                });
              }
            });
          }
        });
      }
      // 编辑
      else {
        // console.log("this.ruleForm.role", this.ruleForm.role);
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            postData("POST", "user/editAuhtority", {
              authorityId: role,
              userId: this.userId,
            }).then(() => {
              // console.log("编辑：", res);
              this.dialogVisible = false;
              this.personnelList();
            });
          }
        });
      }
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      this.roleDialogVisible = false;
    },
    // 跳转人员详情页
    details(row) {
      this.$router.push({
        path: "/account/personnelDatail",
        query: {
          row: encodeURIComponent(JSON.stringify(row)),
        },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
@import url("../../style/flags.css")

.topButton
    display: flex
    justify-content: space-between

.added
    width: 142px
    background: #ff0a0a
    font-size: 15px
    color: #fff
    border: none

    & img
        width: 15px
        height: 15px
        margin-right: 10px

.delete
    width: 142px
    background: #fae1e0
    font-size: 15px
    color: #ff0a0a
    border: none

.el-select
    width: 100% !important

.modify
    border: none
    color: #ff0a0a
    background: none

.preview
    width: 184px
    background: #fae1e0
    font-size: 15px
    color: #ff0a0a
    border: none

.preservation
    width: 184px
    background: #ff0a0a
    font-size: 15px
    color: #fff
    border: none

.dialog-footer
    display: flex
    justify-content: center
    margin-bottom: 10px

// 改为有效的选择器
::v-deep .el-tabs__item:hover
    color: #ff0a0a

::v-deep .el-tabs__active-bar
    background-color: #ff0a0a

::v-deep .el-tabs__item.is-active
    color: #ff0a0a

div .el-tabs__item:focus.is-active.is-focus:not(:active)
    box-shadow: none
</style>
